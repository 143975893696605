.contact-top-container {
  margin: 0px;
}
.contact-block-heading {
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
}

.contact-block-heading .title {
  text-transform: uppercase;
  color: #9a9a9a;
  padding: px;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 1px;
  font-weight: normal;
  text-align: center;
}

.contact-block-heading .sub-title {
  text-transform: uppercase;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 1px;
  font-weight: bold;
  color: #555555;
  text-align: center;
}

.contact-us-form {
  font-family: "Poppins", sans-serif;
  align-items: center;
  margin: 0px !important;
}

.contact-us-form .submit-contact-form {
  border-color: #0a67bd;
  border-radius: 38px;
  letter-spacing: 2px;
  line-height: 14px;
  font-size: 14px;
  background-color: #0a67bd;
  padding: 15px 35px;
  color: #ffffff;
}

.contact-us-form .text-muted {
  font-size: 12px;
  font-style: italic;
  text-transform: lowercase;
}

.align-center {
  margin-bottom: 1em;
  display: flex;
  align-items: center;
}

/*************************************************/
.contact-card {
  font-family: "Poppins", sans-serif;
  color: #555555;
  font-size: 14px;
  letter-spacing: 1px;
  border-radius: 0px;
  border-color: #f6f6f6;
}

.contact-card .title {
  font-size: 16px;
  color: #fff;
  border-radius: 0;
}

.send-footer .send-btn {
  border-radius: 0px;
  background-color: #fb9d17;
  color: #fff;
  width: 100%;
  text-decoration: none;
  box-shadow: none;
}

.contact-us-form .form-label {
  font-family: "Poppins", sans-serif;
  color: #555555;
  font-size: 14px;
  margin-bottom: 0px;
  letter-spacing: 1px;
}
