.home-trip-container {
  margin-top: 4em;
  margin-bottom: 2.2em;
}

.trip-block-heading {
  text-align: left;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  /* margin-top: 2.2em; */
  margin-bottom: 2.2em;
  position: relative;
}

.trip-block-heading .title {
  color: #9a9a9a;
  padding: px;
  text-align: left;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 1px;
  font-weight: normal;

  /* text-align: left;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 0px;
  font-weight: bold;
  color: #555555; */
}

.trip-block-heading .sub-title {
  text-transform: capitalize;
  text-align: left;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.6px;
  font-weight: bold;
  color: #555555;
  /* color: #9a9a9a;
  text-align: left;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 1px;
  font-weight: normal; */
}

.trip-block-description {
  text-transform: none;
  color: #a3a3a3;
  font-size: 16px;
  letter-spacing: 0.6px;
  line-height: 27px;
  font-family: "Poppins", sans-serif;
  height: 60%;
  overflow-y: auto;
  margin-bottom: 6em;
}

.trip-block-img img {
  width: 100%;
  transition: 0.5s;
}

.trip-block-img img:hover {
  box-shadow: 0px 2px 5px 2px #00000020;
  border: 0px;
  border-radius: 0px;
  transform: scale(1.05);
  z-index: 1;
}

.trip-block-heading .book-now-button {
  border-color: #fb9d17;
  border-radius: 0px;
  letter-spacing: 1px;
  line-height: 14px;
  font-size: 14px;
  background-color: #fb9d17;
  padding: 15px 35px;
  color: #ffffff;
}

.bottom-div {
  position: absolute;
  bottom: 0;
}

.bottom-div .for-person-txt {
  font-size: 14px;
  letter-spacing: 1px;
  color: #a3a3a3;
}
