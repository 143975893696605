.footer-block-heading {
  text-align: left;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  margin-top: 2.2em;
  margin-bottom: 2.2em;
}

.footer-block-heading .title {
  color: #ffffff;
  padding: px;
  text-align: left;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 2px;
  font-weight: normal;
}

.footer-block-heading .sub-title {
  text-transform: capitalize;
  text-align: left;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 0px;
  font-weight: bold;
  color: #555555;
}

.footer {
  margin-top: 2.5em;
  background-color: #2f1463;
}

.footer-top-container {
  max-width: 60%;
}

.footer-top-container .footer-block-logo img {
  width: 100%;
}

.footer-top-container .footer-block-text-container {
  color: #fff;
  text-align: left;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  margin-top: 2.2em;
  margin-bottom: 2.2em;
}

.footer-block-text-container .title {
  color: #fb9d17;
  padding: 0px;
  /* text-decoration: underline; */
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 2px;
  font-weight: 600;
}

.footer-block-text-container .item-list ul {
  list-style: inside;
  color: #ffffff;
  padding: 0px;
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: normal;
  text-transform: capitalize;
  line-height: 2em;
}

.footer-block-text-container .item-list ul .list-link {
  cursor: pointer;
}

.footer-block-text-container .item-list ul .list-link:hover {
  cursor: pointer;
  font-weight: 600;
  color: #fff;
}

.footer-block-logo .social-media-icons img {
  height: 2em;
  width: auto;
  margin: 1em;
}

.footer-block-text-container {
  text-align: start;
}

.footer-contact-us-form {
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  margin-top: 2.5em;
  color: #ffffff;
  letter-spacing: 2px;
}

.footer-contact-us-form .contact-us-address img {
  height: 2em;
}

/* .footer-contact-us-form .contact-us-address .address {
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
} */

.align-center {
  margin-bottom: 1em;
  display: flex;
  align-items: center;
}

.footer-contact-us-form .contact-us-address .address ul {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}
