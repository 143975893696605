.services-container {
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  margin-top: 10em;
  margin-bottom: 2.5em;
}

.services-container .title {
  color: #9a9a9a;
  padding: px;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 2px;
  font-weight: normal;
}

.services-container .sub-title {
  text-transform: capitalize;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 0px;
  font-weight: bold;
  color: #555555;
}

.services-container .services-details .details {
  font-family: "Poppins", sans-serif;
  text-transform: initial;
  color: #555555;
  font-size: 16px;
  letter-spacing: 0.6px;
}

.services-list {
  font-family: "Poppins", sans-serif;
  text-transform: initial;
}

.services-list .list-header {
  color: #000 !important;
  text-transform: capitalize !important;
  letter-spacing: 1px !important;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
}
.services-list .list-details {
  font-family: "Poppins", sans-serif;
  text-transform: initial;
  color: #555555;
  font-size: 16px;
  letter-spacing: 0.6px;
}
