.whatss-app-home {
  position: fixed;
  bottom: 1em;
  right: 0.5em;
  text-align: center;
}

.whatss-app-home img {
  border-radius: 50%;
  height: 4em;
  width: 4.2em;
}
