.customize-modal .modal-content {
  border-radius: 0px;
  border-color: #fff;
  font-family: "Poppins", sans-serif;
  color: #555555;
  letter-spacing: 1px;
}

.customize-modal .modal-header .modal-title {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
}

.customize-modal .form-label {
  margin: 0px;
  font-size: 14px;
}

.customize-modal .invalid-feedback {
  font-size: 12px;
}

.modal-footer {
  padding: 0px;
  margin: 0px;
}

.submit-form {
  width: 100%;
  margin: 0px;
  border-radius: 0px;
  background-color: #fb9d17;
  border-color: #fb9d17;
}

.submit-form:hover {
  background-color: #fb9d17;
  border-color: #fb9d17;
}

.submit-form:focus {
  background-color: #fb9d17;
  border-color: #fb9d17;
}
