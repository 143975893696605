.memories {
  margin-top: 25em !important;
  box-sizing: border-box;
}

.gallery {
  box-shadow: 0 0.1rem 0 0 rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  display: grid;
  max-width: 780px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: 180px;
  grid-gap: 20px;
  padding-bottom: 70px;
}

.gallery .item {
  max-width: 180px;
  height: 180px;
  margin: 0 auto;
  cursor: pointer;
  filter: grayscale(40%);
}

.gallery .item:hover {
  filter: none;
  transition: 0.3s ease-out;
  transform: scale(1.9);
}

.gallery img {
  max-width: 180px;
  height: 70%;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 10px 0px,
    rgba(0, 0, 0, 0.5) 0px 2px 15px 0px;
}
