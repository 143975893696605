.nav-bar {
  align-items: center;
  background-color: #fff;
  box-shadow: 0 0 14px rgb(0 0 0 / 15%) !important;
  font-family: "Poppins", sans-serif;
  padding: 0.3em;
}
.nav-logo {
  margin: 1em;
  height: 3em;
  width: 13em;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.nav-bar .nav-link {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  /* line-height: 2em; */
  letter-spacing: 1.1px;
  padding: 25px;
  display: inline-block;
  font-family: "Poppins", sans-serif;
  color: #2f1463 !important;
}

.nav-custome-link .active-nav-link {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  /* line-height: 2em; */
  letter-spacing: 1.1px;
  /* padding: 25px; */
  display: inline-block;
  font-family: "Poppins", sans-serif;
  color: #fb9d17 !important;
}

.nav-link-btn a {
  text-decoration: none !important;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  /* line-height: 2em; */
  letter-spacing: 1.1px;
  /* padding: 25px; */
  display: inline-block;
  font-family: "Poppins", sans-serif;
  color: #2f1463 !important;
}

.nav-link-btn .dropdown-item {
  text-transform: capitalize;
  color: #2f1463 !important;
}

.active-nav-link-btn a {
  text-decoration: none !important;
  color: #fb9d17 !important;
}

.active-nav-link-btn .dropdown-item {
  text-transform: capitalize;
  color: #2f1463 !important;
}

.nav-custome-link .nav-link:hover {
  color: #fb9d17 !important;
}

.off-canvase-nav {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  line-height: 2em;
  letter-spacing: 1.1px;
  padding: 25px;
  display: inline-block;
  font-family: "Poppins", sans-serif;
  color: #2f1463 !important;
}

.nav-dropdown-menu .dropdown-menu {
  text-transform: capitalize;
}
