.about-container {
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  margin-top: 10em !important;
  margin-bottom: 2.5em;
}

.about-container .about-block-heading .title {
  text-transform: uppercase;
  color: #9a9a9a;
  padding: px;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 1px;
  font-weight: normal;
}

.about-container .about-block-heading .sub-title {
  text-transform: uppercase;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 1px;
  font-weight: bold;
  color: #555555;
}

.about-container .about-block-heading .details {
  font-family: "Poppins", sans-serif;
  text-transform: initial;
  margin-top: 1em;
  margin-bottom: 2.5em;
  color: #555555;
  font-size: 16px;
  letter-spacing: 0.6px;
}

.our-team {
  font-family: "Poppins", sans-serif;
  color: #555555;
  letter-spacing: 1px;
  font-weight: normal;
  text-transform: initial;
}

.our-team img {
  height: 15em;
  border: 0px solid orange;
  border-radius: 50%;
  box-shadow: 3px 3px 10px 3px #00000030;
  /* position: relative; */
}

.our-team .title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
}

.our-team .sub-title {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0px;
  color: #9a9a9a;
}
