.title-img {
  height: 20em;
  background-repeat: no-repeat;
  width: 100%;
  background-position: center;
  background-size: cover;
  position: relative;
}

.title-img .category-label {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  letter-spacing: 0.8px;
  color: #fff;
  position: absolute;
  bottom: 0px;
  left: 1em;
}

.details-block-heading .title {
  text-transform: capitalize;
  color: #9a9a9a;
  padding: px;
  font-size: 16px;
  line-height: 14px;
  letter-spacing: 1px;
  font-weight: normal;
}

.details-block-heading .sub-title {
  text-transform: capitalize;
  font-size: 22px;
  line-height: 22px;
  letter-spacing: 1px;
  font-weight: bold;
  color: #555555;
}

.details-block-heading p {
  color: #555555;
  font-size: 16px;
  letter-spacing: 1px;
  /* line-height: 24px; */
}
.div-block {
  margin-top: 2em;
  margin-bottom: 2em;
}

.accordion-item {
  margin-top: 1em;
  margin-bottom: 1em;
  border-radius: 0px !important;
  border: 1px solid #ebebeb !important;
}

.accordion-button:not(.collapsed) {
  color: #000000 !important;
  background-color: #fff !important;
  /* background-color: #9c18ff80 !important; */
}
.accordion-button:focus {
  z-index: 3;
  border-color: #fff;
  outline: 0;
}

.accordion-item:first-of-type .accordion-button {
  border-radius: 0px !important;
}

.right-col-cards {
  margin-top: 1.5em;
  margin-bottom: 1em;
  font-family: "Poppins", sans-serif;
  color: #555555;
  font-size: 14px;
  letter-spacing: 1px;
}

.right-col-cards .right-card {
  font-family: "Poppins", sans-serif;
  color: #555555;
  font-size: 14px;
  letter-spacing: 1px;
  border-radius: 0px;
  border-color: #f6f6f6;
  /* box-shadow: 5px 5px 10px 0px #0096ff1a; */
}

.right-col-cards .right-card .title {
  font-size: 16px;
  color: #fff;
  border-radius: 0;
}

.day-by-day-list {
  font-family: "Poppins", sans-serif;
  color: #555555;
  font-size: 14px;
  letter-spacing: 1px;
}
.day-by-day-list li {
  margin: 0.5em;
}

.day-by-day-list .title h6 {
  font-family: "Poppins", sans-serif;
  color: #555555;
  /* font-size: 14px; */
  letter-spacing: 1px;
}

.policy-list {
  font-family: "Poppins", sans-serif;
  color: #555555;
  font-size: 16px;
  letter-spacing: 1px;
}
.policy-list li {
  margin: 0.5em;
}

.locations {
  font-family: "Poppins", sans-serif;
  color: #555555;
  font-size: 16px;
  letter-spacing: 1px;
  margin-bottom: 2em;
}

.note {
  font-family: "Poppins", sans-serif;
  color: #555555;
  font-size: 14px;
  letter-spacing: 1px;
  margin-bottom: 2em;
  padding: 1em;
}

.note ul {
  margin: 0px;
}
.note ul li {
  margin: 0.5em;
}

.note .hstack {
  align-items: baseline !important;
}

.right-card .send-button {
  background-color: #fff;
}

.right-card .send-footer {
  padding: 0px;
}

.right-card .send-footer .send-btn {
  border-radius: 0px;
  background-color: #fff;
  width: 100%;
  text-decoration: none;
  box-shadow: none;
}

.custome-btn-div {
  padding: 1em;
  cursor: pointer;
  text-align: center;
  background-repeat: no-repeat;
  width: 100%;
  background-position: center;
  background-size: cover;
  position: relative;
  height: 5em;
  transition: 0.5s;
  box-shadow: 5px 5px 5px 2px #00000020;
}

.custome-btn-div h5 {
  color: #fff;
  font-size: 1.5em;
}
.custome-btn-div:hover {
  /* box-shadow: 5px 5px 5px 2px #00000020; */
  border: 0px;
  border-radius: 0px;
  transform: scale(1.01);
  z-index: 1;
}

/* ****************************************************************** */

.related-pkg {
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
  cursor: pointer;
}

.related-pkg:hover {
  filter: none;
  transition: 0.3s ease-out;
  transform: scale(1.1);
}

@media only screen and (max-width: 464px) {
  .related-pkg:hover {
    filter: none;
    transition: 0.3s ease-out;
    transform: scale(1);
  }
}

.related-pkg .title-img {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.5) 0px 2px 5px 0px;
}

.related-pkg .pkg-duration {
  margin-bottom: 0px;
  margin-top: 1.5em;
  font-size: 12px;
  color: #9a9a9a;
}

.related-pkg .pkg-label {
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 14px;
  color: #000 !important;
  text-transform: capitalize;
}
