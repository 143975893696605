.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  max-width: 95%;
  margin-top: 1em;
}

/* *******************************HORIZONTAL IMAGE************************** */

.horizontal-image {
  /* position: relative; */
  margin-top: 2.5em;
  margin-bottom: 2.5em;

  height: 25em;
  background-repeat: no-repeat;
  width: 100%;
  background-position: center;
  background-size: cover;
  position: relative;
}

.horizontal-image img {
  height: 20em;
  width: 100%;
  filter: brightness(0.8);
}

.horizontal-image .horizontal-img-text {
  position: absolute;
  top: 25%;
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.horizontal-image .horizontal-img-text h6 {
  color: #ffffff;
  font-size: 45px;
  line-height: 45px;
  letter-spacing: 0px;
  font-weight: bold;
}

.horizontal-image .view-all-packages {
  text-transform: uppercase;
  border-color: #ffffff;
  border-radius: 38px;
  letter-spacing: 2px;
  line-height: 14px;
  font-size: 14px;
  background-color: #ffffff;
  padding: 15px 35px;
  color: #000;
}

/* *******************************CAROUSEL CSS************************** */
/* .slider {
  height: 20em;
} */
.slider .carousel-caption {
  color: #000 !important;
}

/* .slider .carousel-inner {
  height: 30em;
} */

/* ***************************************PROPOSALS CSS******************************************** */
.block-heading {
  text-align: center;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  margin-top: 2.2em;
  margin-bottom: 2.2em;
}

.block-heading .title {
  color: #9a9a9a;
  padding: px;
  text-align: center;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 2px;
  font-weight: normal;
}

.block-heading .sub-title {
  text-align: center;
  font-size: 36px;
  line-height: 36px;
  letter-spacing: 0px;
  font-weight: bold;
  color: #555555;
}

.proposal-card-col {
  padding: 10px;
}
.proposal-card {
  height: 20rem;
  border: none;
  transition: 0.5s;
}

.proposal-card:hover {
  box-shadow: 0px 2px 5px 2px #00000020;
  border: 0px;
  border-radius: 0px;
  transform: scale(1.05);
  z-index: 1;
}

.proposal-card img {
  position: relative;
  height: 20rem;
  /* background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 20%,
    rgba(0, 0, 0, 1)
  ) !important; */
}

.proposal-card .details {
  position: absolute;
  margin-bottom: 0px;
  width: 100%;
  bottom: 0;
  color: rgb(255, 255, 255);
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 10%,
    rgba(0, 0, 0, 1)
  );
}

.proposal-card .details .title {
  letter-spacing: 1px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0px;
  margin-left: 10px;
}

.proposal-card .details .package {
  text-transform: capitalize;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-left: 10px;
  letter-spacing: 1px;
}

.proposal-card .packages-details {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #0b67bd66;
  color: white;
  text-align: center;
  opacity: 0;
  /* visibility: visible; */
  visibility: hidden;
  -webkit-transition: visibility 0s, opacity 0.6s linear;
  transition: visibility 0s, opacity 0.6s linear;
}
.proposal-card .packages-details p {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.proposal-card .packages-details .title {
  font-family: "Poppins", sans-serif;
  margin-bottom: 1.5em;
  font-weight: 700;
}

.proposal-card .packages-details a {
  color: #fff;
  text-decoration: none;
}

.proposal-card .packages-details .destination-title {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 200;
  margin-bottom: 5px;
  text-transform: initial;
  cursor: pointer;
}
.proposal-card .packages-details .destination-title:hover {
  font-weight: 600;
}

.proposal-card:hover .packages-details {
  visibility: visible;
  opacity: 1;
}

.proposal-card-col .view-destionation-button {
  border-color: #fb9d17;
  /* border-radius: 0px; */
  letter-spacing: 1px;
  /* line-height: 14px; */
  font-size: 14px;
  background-color: #fb9d17;
  padding: 5px 10px;
  color: #ffffff;
}

/* *************************************SCOLLBAR CSS*************************************** */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9dc2e4;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9dc2e4;
}

.proposal-card .card-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  transition: 0.5s ease;
  background-color: #00000070;
}

.proposal-card .overlay-text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.6px;
}
